import React from 'react';
import styled from '@emotion/styled';

import Layout from '../components/layout';
import SEO from '../components/seo';

const PlaceholderText = styled.h1`
  font-size: 8rem;
  font-weight: 700;
  font-style: italic;

  > span {
    color: red;
  }
`;

const PlaceholderSubText = styled.h2`
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 700;

  a {
    text-decoration: none; 
    color: blue;
  }
`;

const PlaceholderContainer = styled.div`
  display: flex; 
  justify-content: center; 
  align-items: center;

  height: 100vh;

  > div {
    text-align: center;
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Y5" />
    <PlaceholderContainer>
      <div>
        <PlaceholderText>Y5<span>.</span></PlaceholderText>
        <PlaceholderSubText>by <a href="https://yihwan.kim" rel="noopener noreferrer">yihwan</a></PlaceholderSubText>
      </div>
    </PlaceholderContainer>
  </Layout>
)

export default IndexPage
